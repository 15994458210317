import { Suspense, useEffect, useRef } from "react";
import { RouterProvider } from "@/providers";
import { QueryClientProvider, QueryClient } from "react-query";
import { useValidateSession } from "./use-session-validate";
import { config } from "./configure";
import { APIProvider } from "@vis.gl/react-google-maps";
import {
  ErrorBoundary,
  AlertProvider,
  Loader,
  FallBackLoader,
  GlobalAlertMessage,
  GlobalAlertSucessMessage,
} from "@cypher/ui";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: "tracked",
    },
  },
});

const MainApp = () => {
  const { validateUser } = useValidateSession();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    isMounted.current = true;

    validateUser();
  }, [validateUser]);

  return (
    <>
      <Suspense fallback={<FallBackLoader />}>
        {/* <ThemesProvider> */}
        <APIProvider
          apiKey={config.google_key}
          libraries={["core", "maps", "places", "geocoding"]}
        >
          <ErrorBoundary>
            <AlertProvider>
              <Loader />
              <RouterProvider />
              <GlobalAlertMessage />
              <GlobalAlertSucessMessage />
            </AlertProvider>
          </ErrorBoundary>
        </APIProvider>
        {/* </ThemesProvider> */}
      </Suspense>
    </>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MainApp />
    </QueryClientProvider>
  );
};
export default App;
