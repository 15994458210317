export const config = {
  api_url: import.meta.env.VITE_APP_API_URL,
  google_key: import.meta.env.VITE_APP_GOOGLE_KEY,
  version: import.meta.env.VITE_REACT_VERSION,
  index_db: {
    db_name: import.meta.env.VITE_DB_NAME,
    db_version: import.meta.env.VITE_DB_VERSION,
    db_store: import.meta.env.VITE_STORE_NAME,
  },
  APP_ENV: import.meta.env.VITE_APP_ENV,
};
