export const ApiRoutes = {
    API: {
        AUTH: {
            LOGIN: "auth/login",
            REGISTER: "auth/register",
            FORGOT_PASSWORD: "auth/forgot_password",
            RESET_PASSWORD: "auth/reset_password",
            ME: "auth/me",
            LOGOUT: "auth/logout",
            VERIFY_EMAIL: "auth/verify_mail",
            RESEND_EMAIL: "auth/resend_verify_mail",
            SEND_OTP: "auth/send_otp",
            VERIFY_OTP: "auth/verify_otp",
            ADD_ADDRESS: "user/add_user_address",
            GET_ADDRESS: "user/all_user_address",
            DELETE_ADDRESS: "user/delete_address",
        },
        PARTNER: {
            MENU: {
                GETSERVICE: "partner/service_masters",
            },
            ONBOARDING: {
                GETsERVICEMASTER: "service",
                GETSERVICECATEGORY: "service/getCategory",
                GETSERVICECATALOG: "service/getCatalogAgainstCategory",
                CREATESERVICE: "service/createServiceList",
            },
        },
        SERVICE: {
            GET_SERVICE_BY_LOCATION: "service/getAgainstLocation",
            GET_CATALOG_BY_LOCATION: "service/selectedCatalogAgainstLocation",
            CREATE: "service_request/create",
            EDIT: "service_request/update",
            DELETE_ITEM: "service_request/delete_item",
        },
        HOME: {
            HOME: "home",
        },
        USER: {
            USER: "user",
            UPDATE: "user/upsert",
        },
        SLOT: {
            SLOT: "slot",
        },
    },
};
