import { createBrowserRouter } from "react-router-dom";
import { Layout } from "@/components";
import { PrivateRouter } from "./privateRouter";
import { ROUTES } from "@/constants";
import { HOME } from "@/page";

export function router() {
  return createBrowserRouter([
    {
      element: (
        <PrivateRouter>
          <Layout />
        </PrivateRouter>
      ),
      errorElement: <>Error</>,
      children: [
        {
          index: true,
          id: ROUTES.HOME.ID,
          path: ROUTES.HOME.PATH,
          element: <HOME />,
        },
      ],
    },
  ]);
}
