import { ROUTES } from "@/constants";

export const allRoles = {
  user: [
    "/",
    "/profile",
    "/best_services",
    "/category_list",
    "/my_booking",
    "/request",
    "/request_details",
    "/my_request",
    "/hotel/list",
    "/hotel/view",
  ],
  partner: [
    ROUTES.PARTNER.HOME.PATH,
    ROUTES.PARTNER.HOTEL.REGISTRATION.PATH,
    ROUTES.PARTNER.REGISTER.PATH,
    ROUTES.PARTNER.ONBOARING.PATH,
  ],
};

const roleRights = new Map(Object.entries(allRoles));

export function getAccess(role: string, path: string) {
  const userRights = roleRights.get(role);
  return userRights?.includes(path as any) ? true : false;
}
