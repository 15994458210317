export const ROUTES = {
  ID: "base",
  LOGIN: {
    PATH: "/login",
    ID: "login",
  },
  SIGNUP: {
    PATH: "/signup",
    ID: "signup",
  },
  FORGOT: {
    PATH: "/forgot_password",
    ID: "forgot_password",
  },
  RESET: {
    PATH: "/reset_password",
    ID: "reset_password",
  },
  HOME: {
    PATH: "/",
    ID: "home",
  },
  PROFILE: {
    PATH: "/profile",
    ID: "profile",
  },
  VERIFY: {
    PATH: "/verification",
    ID: "verification",
  },
  WILDCARD: {
    PATH: "/*",
    ID: "wildcard",
  },

  HOTEL: {
    HOTELLISTPAGE: {
      PATH: "/hotel/list",
      ID: "hotel-list",
    },
    HOTELPAGE: {
      PATH: "/hotel/view",
      ID: "hotel-view",
    },
    HOTELCHECKOUT: {
      PATH: "/hotel/checkout",
      ID: "hotel-checkout",
    },
  },

  PARTNER: {
    HOME: {
      PATH: "/partner",
      ID: "partner_home",
    },
    LOGIN: {
      PATH: "/partner_login",
      ID: "partner_login",
    },
    REGISTER: {
      PATH: "/partner_register",
      ID: "partner_register",
    },
    FORGOT_PASSWORD: {
      PATH: "/partner_forgot_password",
      ID: "partner_forgot_password",
    },
    HOTEL: {
      REGISTRATION: {
        PATH: "/hotel_registration",
        ID: "/hotel_registration",
      },
    },
    COMPSPACE: {
      PATH: "/comp_space",
      ID: "comp_space",
    },
    ONBOARING: {
      PATH: "/partner_onboarding",
      ID: "partner_onboarding",
    },
  },
  REQUEST: {
    PATH: "/request",
    ID: "request",
  },
  REQUEST_DETAILS: {
    PATH: "/request_details",
    ID: "request_details",
  },
  API: {
    AUTH: {
      LOGIN: "auth/login",
      REGISTER: "auth/register",
      FORGOT_PASSWORD: "auth/forgot_password",
      RESET_PASSWORD: "auth/reset_password",
      ME: "auth/me",
      LOGOUT: "auth/logout",
      VERIFY_EMAIL: "auth/verify_mail",
      RESEND_EMAIL: "auth/resend_verify_mail",
      SEND_OTP: "auth/send_otp",
      VERIFY_OTP: "auth/verify_otp",
      ADD_ADDRESS: "user/add_user_address",
      GET_ADDRESS: "user/all_user_address",
      DELETE_ADDRESS: "user/delete_address",
    },
    PARTNER: {
      MENU: {
        GETSERVICE: "partner/service_masters",
      },
      ONBOARDING: {
        GETsERVICEMASTER: "service",
        GETSERVICECATEGORY: "service/getCategory",
        GETSERVICECATALOG: "service/getCatalogAgainstCategory",
        CREATESERVICE: "service/createServiceList",
      },
    },
    SERVICE: {
      GET_SERVICE_BY_LOCATION: "service/getAgainstLocation",
      GET_CATALOG_BY_LOCATION: "service/selectedCatalogAgainstLocation",
      CREATE: "service_request/create",
      EDIT: "service_request/update",
      DELETE_ITEM: "service_request/delete_item",
    },
    HOME: {
      HOME: "home",
    },
    USER: {
      USER: "user",
      UPDATE: "user/upsert",
    },
    SLOT: {
      SLOT: "slot",
    },
  },
} as const;

export const LOCALSTORAGEKEYS = {
  AUTHTOKEN: "accessToken",
  REFRESHTOKEN: "refresh_token",
};

export const THEMES = {
  default: "default",
  dark: "dark",
};
export const ALERTPROPS: {
  vertical: {
    top: "top";
    bottom: "bottom";
  };
  horizontal: {
    left: "left";
    right: "right";
    center: "center";
  };
  severity: {
    success: "success";
    error: "error";
    warning: "warning";
    info: "info";
  };
} = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

export const BOLD: string = "Bold";
export const REGULAR: string = "Regular";
export const SEMIBOLD: string = "Semi-Bold";
export const EXTRABOLD: string = "Extra-Bold";
export const MEDIUM: string = "Medium";
export const BORDER_RADIUS: string = "12px";
