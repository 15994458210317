import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import { localizeDate } from "@cypher/utils";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    ns: ["common"],
    defaultNS: "common",
  });

if (i18n.services.formatter) {
  i18n.services.formatter.add("DATE_HUGE", (value, lng) => {
    return localizeDate(new Date(value), lng || "en");
  });
}

export default i18n;
