import { useActiveUser, useSessionValid } from "@/use-global-store";
import { ReactNode, useState, useEffect } from "react";
import { ROUTES } from "@/constants";
import { useNavigate } from "react-router-dom";
import { getAccess } from "@/utils";
import { QueryString } from "@cypher/utils";
interface PrivateRouterProps {
  children: ReactNode;
}

function PrivateRouter(props: PrivateRouterProps): ReactNode {
  const { children } = props;

  const navigate = useNavigate();
  const activeUser = useActiveUser();
  const QueryParams = QueryString();

  const sessionValidated = useSessionValid();

  const [permission, setPermission] = useState<
    "Allowed" | "Not Allowed" | null
  >("Allowed");

  useEffect(() => {
    if (!sessionValidated) {
      return;
    }

    if (!activeUser) {
      if (location.pathname !== "/" || QueryParams.action === "authenticated") {
        navigate(`${ROUTES.HOME.PATH}?action=login`);
        return;
      }
    } else {
      if (activeUser.privilege_class === "partner") {
        if (location.pathname === "/") {
          navigate(`${ROUTES.PARTNER.HOME.PATH}?action=authenticated`);
        }
      } else {
        navigate(`${location.pathname}?action=authenticated`);
      }

      const canAccess = getAccess(
        activeUser.privilege_class,
        location.pathname,
      );
      if (canAccess) {
        setPermission("Allowed");
      } else {
        setPermission("Not Allowed");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser, sessionValidated]);

  switch (permission) {
    case "Allowed":
      return children;
    case "Not Allowed":
      return <div>"You are not allowed"</div>;
  }
}

export { PrivateRouter };
