import { create } from "zustand";
import { ALERTPROPS } from "@cypher/constants";
const useGlobalStore = create((set) => ({
    loggedInUser: null,
    sessionValidated: null,
    is_server_down: false,
    message: {
        open: false,
        severity: ALERTPROPS.severity.success,
        msg: "",
        vertical: ALERTPROPS.vertical.top,
        horizontal: ALERTPROPS.horizontal.center,
    },
    actions: {
        setSessionValidated: (value) => {
            if (!value) {
                set(() => ({
                    loggedInUser: null,
                    sessionValidated: "invalid",
                }));
                return;
            }
            const result = {
                loggedInUser: value.loggedInUser,
                sessionValidated: "valid",
            };
            set(() => result);
        },
        setShowProgress: (value) => {
            const result = {
                showProgress: value,
            };
            if (!value) {
                result["modelProgress"] = false;
            }
            set(() => result);
            return result;
        },
        setModalProgress: (value) => {
            set(() => ({
                showProgress: value,
                modelProgress: value,
            }));
        },
        setAlertMessage: (message) => {
            set(() => ({
                message: message,
            }));
        },
        setServerDown: (value) => {
            set(() => ({
                is_server_down: value,
            }));
        },
        setCountry: (value, key) => {
            set(() => ({
                [key]: value,
            }));
        },
    },
}));
export function useGlobalStoreAction() {
    return useGlobalStore((state) => state.actions);
}
export function useAlertMessage() {
    return useGlobalStore((state) => state.message);
}
export function useActiveUser() {
    return useGlobalStore((state) => state.loggedInUser);
}
export function useSessionValid() {
    return useGlobalStore((state) => state.sessionValidated);
}
export function useShowProgress() {
    return useGlobalStore((state) => state.showProgress);
}
export function useModelProgress() {
    return useGlobalStore((state) => state.modelProgress);
}
export function useServerDown() {
    return useGlobalStore((state) => state.is_server_down);
}
export function useCountry() {
    const { is_country, is_currency } = useGlobalStore((state) => state);
    return { is_country, is_currency };
}
