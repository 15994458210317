import { create } from "zustand";
import {
  useGlobalStoreAction,
  useActiveUser,
  useAlertMessage,
  useCountry,
  useModelProgress,
  useServerDown,
  useSessionValid,
  useShowProgress,
} from "@cypher/store";

type GlobalStore = {
  partnerService: null;

  actions: GlobalStoreActions;
};

type GlobalStoreActions = {
  setPartnerService: (value: null) => void;
};

const useGlobalStore = create<GlobalStore>((set) => ({
  partnerService: null,

  actions: {
    setPartnerService: (value) => {
      set(() => ({
        partnerService: value,
      }));
    },
  },
}));

export function useGlobalClientStoreAction() {
  return useGlobalStore((state) => state.actions);
}

export function usePartnerService() {
  return useGlobalStore((state) => state.partnerService);
}

export {
  useGlobalStoreAction,
  useActiveUser,
  useAlertMessage,
  useCountry,
  useModelProgress,
  useServerDown,
  useSessionValid,
  useShowProgress,
};
