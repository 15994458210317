export async function validateApiResponse(apiResponse) {
    const statusCode = apiResponse?.status;
    if (statusCode >= 200 && statusCode <= 299) {
        return apiResponse;
    }
    if (statusCode === 401) {
        return apiResponse.data;
    }
    throw {
        statusCode,
        type: "error",
        message: apiResponse?.data?.message,
    };
}
