import { useMutation } from "react-query";
import { apiRequest } from "../../api-manager";
import { LOCALSTORAGEKEYS, ApiRoutes } from "@cypher/constants";
import { useGlobalStoreAction } from "@cypher/store";
async function createUserApi(data) {
    const url = ApiRoutes.API.AUTH.REGISTER;
    return await apiRequest({ url, method: "POST", data });
}
export function useRegister(onSuccess, onError) {
    return useMutation(createUserApi, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response);
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
}
async function loginUserApi(data) {
    const url = data.affiliation
        ? `${ApiRoutes.API.AUTH.LOGIN}?affiliation=${data.affiliation}`
        : ApiRoutes.API.AUTH.LOGIN;
    delete data["affiliation"];
    return await apiRequest({ url, method: "POST", data });
}
export function useLogin(onSuccess) {
    return useMutation(loginUserApi, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response);
            }
        },
    });
}
async function forgotApi(data) {
    const url = ApiRoutes.API.AUTH.FORGOT_PASSWORD;
    return await apiRequest({ url, method: "POST", data });
}
export function useForgotPassword(onSuccess, onError) {
    return useMutation(forgotApi, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response);
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
}
async function resetApi(data) {
    const url = ApiRoutes.API.AUTH.RESET_PASSWORD;
    return await apiRequest({ url, method: "POST", data });
}
export function useResetPassword(onSuccess, onError) {
    return useMutation(resetApi, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response);
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
}
async function validateAuthTokenApi() {
    return await apiRequest({ url: ApiRoutes.API.AUTH.ME, method: "GET" });
}
// validate user
export function useValidateUser(onSuccess) {
    const setGlobalStore = useGlobalStoreAction();
    return useMutation(validateAuthTokenApi, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response);
            }
        },
        onSettled: () => {
            setGlobalStore.setModalProgress(false);
        },
    });
}
// logout
async function logoutApi() {
    const url = ApiRoutes.API.AUTH.LOGOUT;
    return await apiRequest({
        url,
        method: "POST",
        data: {
            refresh_token: localStorage.getItem(LOCALSTORAGEKEYS.REFRESHTOKEN) ?? "",
        },
    });
}
export function useLogOut(onSuccess) {
    return useMutation(logoutApi, {
        onSuccess: (response) => {
            localStorage.clear();
            if (onSuccess) {
                onSuccess(response);
            }
        },
        onError: () => { },
    });
}
// verify email
async function verifyApi(data) {
    const url = ApiRoutes.API.AUTH.VERIFY_EMAIL;
    return await apiRequest({ url, method: "POST", data });
}
export function useVerify(onSuccess, onError) {
    return useMutation(verifyApi, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response);
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
}
