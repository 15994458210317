import { useGlobalStoreAction } from "@/use-global-store";

import { useValidateUser } from "@cypher/query";

export function useValidateSession() {
  const { setSessionValidated } = useGlobalStoreAction();
  const { mutate: validateUser, isLoading } = useValidateUser((response) => {
    const { data } = response;
    if (
      response.status === 403 ||
      response.status === 401 ||
      data?.length === 0
    ) {
      setSessionValidated(null);
      return;
    }

    const loggedInUser = {
      username: data[0].first_name,
      email: data[0].email_id,
      privilege_class: data[0].affiliation,
      id: data[0].id,
    };

    setSessionValidated({
      loggedInUser,
    });
  });

  return {
    validateUser,
    isLoading,
  };
}
