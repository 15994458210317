// src/components/title.tsx
import { jsx } from "react/jsx-runtime";
var Title = () => {
  return /* @__PURE__ */ jsx("div", { className: "flex", children: "Title Component From the Mono Repo " });
};

// ../constants/dist/fonts.js
var SEMIBOLD = "Semi-Bold";

// ../constants/dist/providers.js
var ALERTPROPS = {
  vertical: {
    top: "top",
    bottom: "bottom"
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center"
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info"
  }
};

// src/components/alert.tsx
import { IconButton } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { jsx as jsx2 } from "react/jsx-runtime";
var Alerts = (props) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const action = /* @__PURE__ */ jsx2(React.Fragment, { children: /* @__PURE__ */ jsx2(
    IconButton,
    {
      size: "small",
      "aria-label": "close",
      color: "inherit",
      onClick: handleClose,
      children: /* @__PURE__ */ jsx2(CloseIcon, { fontSize: "small" })
    }
  ) });
  useEffect(() => {
    setOpen(props.open);
  }, [props]);
  return /* @__PURE__ */ jsx2(
    Snackbar,
    {
      id: "main_alert_snackbar",
      anchorOrigin: {
        vertical: props.vertical,
        horizontal: props.horizontal
      },
      open,
      autoHideDuration: 3e3,
      onClose: handleClose,
      action,
      children: /* @__PURE__ */ jsx2(
        MuiAlert,
        {
          id: "main_alert",
          severity: props.severity,
          elevation: 6,
          variant: "filled",
          sx: { color: "primary.light", fontFamily: SEMIBOLD },
          children: props.msg
        }
      )
    }
  );
};

// src/components/loader.tsx
import { useIsFetching } from "react-query";
import { useModelProgress, useShowProgress } from "@cypher/store";
import LinearProgress from "@mui/material/LinearProgress";
import { Backdrop, Box } from "@mui/material";
import { jsx as jsx3, jsxs } from "react/jsx-runtime";
function Loader() {
  const isFetching = useIsFetching();
  const showProgress = useShowProgress();
  const modelProgress = useModelProgress();
  return /* @__PURE__ */ jsxs("div", { children: [
    modelProgress && /* @__PURE__ */ jsx3(
      Backdrop,
      {
        open: !modelProgress,
        sx: {
          backgroundColor: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1
        },
        children: /* @__PURE__ */ jsxs(Box, { children: [
          /* @__PURE__ */ jsx3("img", { src: "/assets/loader.gif" }),
          /* @__PURE__ */ jsx3(Title, {})
        ] })
      }
    ),
    (isFetching || showProgress) && !modelProgress && /* @__PURE__ */ jsx3(
      LinearProgress,
      {
        style: {
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          zIndex: 1e4
        }
      }
    )
  ] });
}

// src/components/fallbackLoader.tsx
import { Box as Box2 } from "@mui/material";
import { jsx as jsx4 } from "react/jsx-runtime";
function FallBackLoader() {
  return /* @__PURE__ */ jsx4(
    Box2,
    {
      sx: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      children: /* @__PURE__ */ jsx4("img", { src: "/assets/loader.gif" })
    }
  );
}

// src/components/globalAlertSuccess.tsx
import { useAlertMessage } from "@cypher/store";
import { Fragment, jsx as jsx5 } from "react/jsx-runtime";
function GlobalAlertSucessMessage() {
  const globalMessage = useAlertMessage();
  return /* @__PURE__ */ jsx5(Fragment, { children: globalMessage && /* @__PURE__ */ jsx5(
    Alerts,
    {
      open: globalMessage?.open,
      severity: globalMessage?.severity,
      msg: globalMessage?.msg,
      vertical: globalMessage?.vertical,
      horizontal: globalMessage?.horizontal
    }
  ) });
}

// src/components/globalAlertError.tsx
import React2 from "react";
import { useQueryClient } from "react-query";
import { useAlertMessage as useAlertMessage2, useGlobalStoreAction } from "@cypher/store";
import { Fragment as Fragment2, jsx as jsx6 } from "react/jsx-runtime";
function GlobalAlertMessage() {
  const globalMessage = useAlertMessage2();
  const globalStoreAction = useGlobalStoreAction();
  const queryClient = useQueryClient();
  React2.useEffect(() => {
    const mutationCache = queryClient.getMutationCache();
    const unsubscribeMutation = mutationCache.subscribe((mutation) => {
      if (mutation && mutation.state.error) {
        const { message, statusCode } = mutation.state.error;
        if (!statusCode) {
          return globalStoreAction.setServerDown(true);
        }
        globalStoreAction.setAlertMessage({
          horizontal: "center",
          msg: message,
          open: true,
          vertical: "top",
          severity: "error"
        });
        globalStoreAction.setServerDown(false);
      }
    });
    const queryCache = queryClient.getQueryCache();
    const unsubscribeQueryCache = queryCache.subscribe((query) => {
      if (query && query.query && query.query.state && query.query.state.error) {
        const { message, statusCode } = query.query.state.error;
        if (!statusCode) {
          return globalStoreAction.setServerDown(true);
        }
        if (statusCode === 401) {
          window.location.href = "/";
          return;
        }
        globalStoreAction.setAlertMessage({
          horizontal: "right",
          msg: message,
          open: true,
          vertical: "top",
          severity: "error"
        });
        globalStoreAction.setServerDown(false);
      }
    });
    return () => {
      unsubscribeMutation();
      unsubscribeQueryCache();
    };
  }, [globalStoreAction, queryClient]);
  return /* @__PURE__ */ jsx6(Fragment2, { children: globalMessage.severity !== "success" && /* @__PURE__ */ jsx6(
    Alerts,
    {
      open: globalMessage?.open,
      severity: globalMessage?.severity,
      msg: globalMessage?.msg,
      vertical: globalMessage?.vertical,
      horizontal: globalMessage?.horizontal
    }
  ) });
}

// src/components/dialog.tsx
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  Hidden,
  Paper,
  Drawer,
  Button
} from "@mui/material";
import React3 from "react";
import CloseIcon2 from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Fragment as Fragment3, jsx as jsx7, jsxs as jsxs2 } from "react/jsx-runtime";
var Transition = React3.forwardRef(function Transition2(props, ref) {
  return /* @__PURE__ */ jsx7(Slide, { direction: "up", ref, ...props });
});
var DialogRoot = ({
  children,
  open = false,
  onClose = () => false,
  maxWidth = "md",
  title = "",
  fullScreen = false,
  isNotTitle = false
}) => {
  const classes = {
    root: {
      width: "100%",
      "& .MuiDialog-paper": {
        width: "100%"
      }
    }
  };
  const titleComponent = () => {
    return /* @__PURE__ */ jsx7(
      DialogTitle,
      {
        sx: {
          borderBottom: "1px solid",
          borderColor: "border.main",
          padding: "8px"
        },
        children: /* @__PURE__ */ jsxs2(
          Stack,
          {
            alignItems: "center",
            direction: "row",
            justifyContent: "space-between",
            children: [
              /* @__PURE__ */ jsx7(Typography, { variant: "h5", children: title }),
              /* @__PURE__ */ jsx7(Button, { onClick: onClose, children: /* @__PURE__ */ jsx7(CloseIcon2, {}) })
            ]
          }
        )
      }
    );
  };
  const bodyComponent = () => {
    return /* @__PURE__ */ jsx7(DialogContent, { sx: { padding: "0px" }, children });
  };
  const PaperWithBorderRadius = (props) => {
    return /* @__PURE__ */ jsx7(Paper, { ...props, sx: { borderRadius: "20px" } });
  };
  return /* @__PURE__ */ jsxs2(Fragment3, { children: [
    /* @__PURE__ */ jsx7(Hidden, { smDown: true, children: /* @__PURE__ */ jsxs2(
      Dialog,
      {
        sx: classes.root,
        PaperComponent: PaperWithBorderRadius,
        TransitionComponent: Transition,
        maxWidth,
        open,
        onClose,
        fullScreen,
        children: [
          !isNotTitle && titleComponent(),
          bodyComponent()
        ]
      }
    ) }),
    /* @__PURE__ */ jsx7(Hidden, { smUp: true, children: /* @__PURE__ */ jsxs2(Drawer, { anchor: "bottom", open, onClose, children: [
      !isNotTitle && titleComponent(),
      bodyComponent()
    ] }) })
  ] });
};

// src/components/icons/addIcon.tsx
import { Add as MUIAddIcon } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { jsx as jsx8 } from "react/jsx-runtime";
function AddIcon(Props) {
  return /* @__PURE__ */ jsx8(SvgIcon, { ...Props, children: /* @__PURE__ */ jsx8(MUIAddIcon, {}) });
}

// src/components/icons/verifiedIcon.ts
import VerifiedIcon from "@mui/icons-material/Verified";
var verifiedIcon_default = VerifiedIcon;

// src/components/icons/wifiIcon.tsx
import { Wifi as MUIPlaceIcon } from "@mui/icons-material";
import { jsx as jsx9 } from "react/jsx-runtime";
function WifiIcon(Props) {
  return /* @__PURE__ */ jsx9(MUIPlaceIcon, { ...Props });
}

// src/components/icons/startIcon.tsx
import MUIStartIcon from "@mui/icons-material/Star";
import { SvgIcon as SvgIcon2 } from "@mui/material";
import { jsx as jsx10 } from "react/jsx-runtime";
function StarIcon(Props) {
  return /* @__PURE__ */ jsx10(SvgIcon2, { ...Props, children: /* @__PURE__ */ jsx10(MUIStartIcon, {}) });
}

// src/components/icons/arrowBackwardIcon.tsx
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { SvgIcon as SvgIcon3 } from "@mui/material";
import { jsx as jsx11 } from "react/jsx-runtime";
function ArrowBackwardIcon(Props) {
  return /* @__PURE__ */ jsx11(SvgIcon3, { ...Props, children: /* @__PURE__ */ jsx11(ArrowBackIosIcon, {}) });
}

// src/components/icons/arrowForwardIcon.tsx
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { SvgIcon as SvgIcon4 } from "@mui/material";
import { jsx as jsx12 } from "react/jsx-runtime";
function ArrowForwardwardIcon(Props) {
  return /* @__PURE__ */ jsx12(SvgIcon4, { ...Props, children: /* @__PURE__ */ jsx12(ArrowForwardIosIcon, {}) });
}

// src/components/icons/doorIcon.tsx
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { jsx as jsx13 } from "react/jsx-runtime";
function DoorIcon(Props) {
  return /* @__PURE__ */ jsx13(MeetingRoomIcon, { ...Props });
}

// src/components/icons/modeEditIcon.tsx
import { ModeEdit as MUIModeEditIcon } from "@mui/icons-material";
import { jsx as jsx14 } from "react/jsx-runtime";
function ModeEditIcon(Props) {
  return /* @__PURE__ */ jsx14(MUIModeEditIcon, { ...Props });
}

// src/components/icons/placeIcon.tsx
import { Place as MUIPlaceIcon2 } from "@mui/icons-material";
import { jsx as jsx15 } from "react/jsx-runtime";
function PlaceIcon(Props) {
  return /* @__PURE__ */ jsx15(MUIPlaceIcon2, { ...Props });
}

// src/components/icons/closeIcon.tsx
import { Close as MUIClose } from "@mui/icons-material";
import { jsx as jsx16 } from "react/jsx-runtime";
function CloseIcon3(Props) {
  return /* @__PURE__ */ jsx16(MUIClose, { ...Props });
}

// src/components/icons/checkCircleIcon.tsx
import { CheckCircle as MUICheckCircle } from "@mui/icons-material";
import { jsx as jsx17 } from "react/jsx-runtime";
function CheckCircleIcon(Props) {
  return /* @__PURE__ */ jsx17(MUICheckCircle, { ...Props });
}

// src/components/icons/removeIcon.tsx
import { Remove as MUIRemoveIcon } from "@mui/icons-material";
import { SvgIcon as SvgIcon5 } from "@mui/material";
import { jsx as jsx18 } from "react/jsx-runtime";
function RemoveIcon(Props) {
  return /* @__PURE__ */ jsx18(SvgIcon5, { ...Props, children: /* @__PURE__ */ jsx18(MUIRemoveIcon, {}) });
}

// src/components/icons/swapHorizaontalIcon.tsx
import { SwapHoriz as MUISwapHorizIcon } from "@mui/icons-material";
import { jsx as jsx19 } from "react/jsx-runtime";
function SwapHorizIcon(Props) {
  return /* @__PURE__ */ jsx19(MUISwapHorizIcon, { ...Props });
}

// src/components/icons/expandLessIcon.tsx
import { ExpandLess } from "@mui/icons-material";
import { jsx as jsx20 } from "react/jsx-runtime";
function ExpandLessIcon(Props) {
  return /* @__PURE__ */ jsx20(ExpandLess, { ...Props });
}

// src/components/icons/expandMoreIcon.tsx
import { ExpandMore } from "@mui/icons-material";
import { jsx as jsx21 } from "react/jsx-runtime";
function ExpandMoreIcon(Props) {
  return /* @__PURE__ */ jsx21(ExpandMore, { ...Props });
}

// src/providers/alert.tsx
import { createContext, useMemo, useState as useState2 } from "react";
import { Fragment as Fragment4, jsx as jsx22, jsxs as jsxs3 } from "react/jsx-runtime";
var AlertContext = createContext({
  open: false,
  severity: ALERTPROPS.severity.success,
  msg: "",
  vertical: ALERTPROPS.vertical.top,
  horizontal: ALERTPROPS.horizontal.center,
  onclose: () => null,
  setSnack: () => null
});
var AlertProvider = ({ children }) => {
  const [alertState, setAlertState] = useState2({
    open: false,
    severity: ALERTPROPS.severity.success,
    msg: "",
    vertical: ALERTPROPS.vertical.top,
    horizontal: ALERTPROPS.horizontal.center
  });
  const setSnack = (value) => {
    setAlertState(value);
  };
  const onclose = () => {
    return null;
  };
  const useAlertMemo = () => {
    return useMemo(() => {
      return /* @__PURE__ */ jsx22(Fragment4, { children: alertState?.open && /* @__PURE__ */ jsx22(
        Alerts,
        {
          open: alertState?.open,
          severity: alertState?.severity,
          msg: alertState?.msg,
          vertical: alertState?.vertical,
          horizontal: alertState?.horizontal
        }
      ) });
    }, [alertState]);
  };
  return /* @__PURE__ */ jsx22(Fragment4, { children: /* @__PURE__ */ jsxs3(AlertContext.Provider, { value: { ...alertState, setSnack, onclose }, children: [
    children,
    useAlertMemo()
  ] }) });
};

// src/providers/errorBoundary.tsx
import { Component } from "react";
import { jsx as jsx23, jsxs as jsxs4 } from "react/jsx-runtime";
var ErrorBoundary = class extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
  }
  render() {
    if (this.state?.errorInfo) {
      return /* @__PURE__ */ jsxs4("div", { children: [
        /* @__PURE__ */ jsx23("h2", { children: "Something went wrong." }),
        /* @__PURE__ */ jsxs4("details", { style: { whiteSpace: "pre-wrap" }, children: [
          this.state?.error && this.state?.error.toString(),
          /* @__PURE__ */ jsx23("br", {}),
          this.state?.errorInfo.componentStack
        ] })
      ] });
    }
    return this.props.children;
  }
};

// src/hooks/useAlert.ts
import React5 from "react";
var useAlert = () => React5.useContext(AlertContext);
export {
  AddIcon,
  AlertContext,
  AlertProvider,
  Alerts,
  ArrowBackwardIcon,
  ArrowForwardwardIcon,
  CheckCircleIcon,
  CloseIcon3 as CloseIcon,
  DialogRoot,
  DoorIcon,
  ErrorBoundary,
  ExpandLessIcon,
  ExpandMoreIcon,
  FallBackLoader,
  GlobalAlertMessage,
  GlobalAlertSucessMessage,
  Loader,
  ModeEditIcon,
  PlaceIcon,
  RemoveIcon,
  StarIcon,
  SwapHorizIcon,
  Title,
  verifiedIcon_default as VerifiedIcon,
  WifiIcon,
  useAlert
};
