import axios from "axios";
import { validateApiResponse, QueryString, formatDate } from "@cypher/utils";
import { LOCALSTORAGEKEYS } from "@cypher/constants";
function constructQueryFromParams(params) {
    if (!params) {
        return ""; // or handle the undefined case as needed
    }
    let formQuery = "";
    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (value === null ||
            value === undefined ||
            (Array.isArray(value) && value.length == 0)) {
            return;
        }
        if (formQuery !== "") {
            formQuery += "&";
        }
        if (Array.isArray(value)) {
            const filteredValue = value.filter((item) => item !== null && item !== undefined);
            if (filteredValue.length === 1) {
                formQuery += `${key}=${filteredValue[0] instanceof Date
                    ? formatDate(filteredValue[0], "yyyy-MM-dd")
                    : filteredValue[0]}`;
            }
            else if (filteredValue.length > 0) {
                if (filteredValue[0] instanceof Date) {
                    formQuery += `${key}__BETWEEN=${filteredValue
                        .map((date) => formatDate(date, "yyyy-MM-dd HH:mm:ss"))
                        .join(",")}`;
                }
                else {
                    formQuery += `${key}__in=${filteredValue.join(",")}`;
                }
            }
        }
        else if (value instanceof Date) {
            formQuery += `${key}=${formatDate(value, "yyyy-MM-dd")}`;
        }
        else {
            formQuery += `${key}=${value}`;
        }
    });
    return formQuery;
}
const axiosInstance = axios.create();
export async function apiRequest({ url, method = "GET", fields, queryParams, data, }) {
    let apiURL = `${import.meta.env.VITE_APP_API_URL}${url}`;
    if (fields && fields.length > 0) {
        apiURL += `fields=${fields.join(",")}&`;
    }
    if (queryParams) {
        apiURL += "?";
        apiURL += constructQueryFromParams(queryParams);
    }
    function getHeaders() {
        const Headers = {
            ["Content-Type"]: "application/json",
        };
        return Headers;
    }
    return axiosInstance({
        url: apiURL,
        data: data,
        withCredentials: true,
        headers: {
            ...getHeaders(),
        },
        method,
    }).then((response) => {
        return response.data;
    }, (error) => {
        return validateApiResponse(error.response);
    });
}
// Add a response interceptor
axiosInstance.interceptors.response.use((response) => {
    // If the response is successful, return it
    return response;
}, async (error) => {
    const originalRequest = error.config;
    const QueryParams = QueryString();
    // If the API responds with a 401 Unauthorized
    if (error.response.status === 401 &&
        !originalRequest._retry &&
        QueryParams.action !== "login") {
        originalRequest._retry = true;
        try {
            // Refresh the access token
            const response = await apiRequest({
                url: "auth/refresh-tokens",
                method: "GET",
                queryParams: {
                    refresh_token: localStorage.getItem(LOCALSTORAGEKEYS.REFRESHTOKEN),
                },
            });
            if (response.success) {
                localStorage.setItem(LOCALSTORAGEKEYS.REFRESHTOKEN, response.data[0].token.refresh.token);
                // Retry the original request with the new token
                return axiosInstance(originalRequest);
            }
        }
        catch (refreshError) {
            // For example, redirect to login page
            window.location.href = "/?action=login";
            // eslint-disable-next-line no-console
            console.error("Refresh token failed", refreshError);
            localStorage.removeItem(LOCALSTORAGEKEYS.REFRESHTOKEN);
        }
    }
    // If the error is not a 401 or refresh token fails, reject the promise
    return Promise.reject(error);
});
